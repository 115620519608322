@font-face {
    font-family: 'Colaborate';
    src: url(fonts/subset-Colaborate-Bold.eot);
    src: url(fonts/subset-Colaborate-Bold.eot?#iefix) format('embedded-opentype'),
        url(fonts/subset-Colaborate-Bold.woff2) format('woff2'),
        url(fonts/subset-Colaborate-Bold.woff) format('woff'),
        url(fonts/subset-Colaborate-Bold.ttf) format('truetype'),
        url(fonts/subset-Colaborate-Bold.svg#Colaborate-Bold) format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Colaborate';
    src: url(fonts/subset-Colaborate-Thin.eot);
    src: url(fonts/subset-Colaborate-Thin.eot?#iefix) format('embedded-opentype'),
        url(fonts/subset-Colaborate-Thin.woff2) format('woff2'),
        url(fonts/subset-Colaborate-Thin.woff) format('woff'),
        url(fonts/subset-Colaborate-Thin.ttf) format('truetype'),
        url(fonts/subset-Colaborate-Thin.svg#Colaborate-Thin) format('svg');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Colaborate';
    src: url(fonts/subset-Colaborate-Regular.eot);
    src: url(fonts/subset-Colaborate-Regular.eot?#iefix) format('embedded-opentype'),
        url(fonts/subset-Colaborate-Regular.woff2) format('woff2'),
        url(fonts/subset-Colaborate-Regular.woff) format('woff'),
        url(fonts/subset-Colaborate-Regular.ttf) format('truetype'),
        url(fonts/subset-Colaborate-Regular.svg#Colaborate-Regular) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Colaborate';
    src: url(fonts/subset-Colaborate-Medium.eot);
    src: url(fonts/subset-Colaborate-Medium.eot?#iefix) format('embedded-opentype'),
        url(fonts/subset-Colaborate-Medium.woff2) format('woff2'),
        url(fonts/subset-Colaborate-Medium.woff) format('woff'),
        url(fonts/subset-Colaborate-Medium.ttf) format('truetype'),
        url(fonts/subset-Colaborate-Medium.svg#Colaborate-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'ColaborateLight';
    src: url(fonts/subset-Colaborate-Light.eot);
    src: url(fonts/subset-Colaborate-Light.eot?#iefix) format('embedded-opentype'),
        url(fonts/subset-Colaborate-Light.woff2) format('woff2'),
        url(fonts/subset-Colaborate-Light.woff) format('woff'),
        url(fonts/subset-Colaborate-Light.ttf) format('truetype'),
        url(fonts/subset-Colaborate-Light.svg#Colaborate-Light) format('svg');
    font-weight: 300;
    font-style: normal;
}

