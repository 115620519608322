html, body {
  padding: 0;
  margin: 0;
  height: 100%; }

ul {
  list-style-image: url(img/icons/dot.svg); }

h1 {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 30px;
  line-height: 1.4;
  color: #01314b;
  text-align: center; }

h2 {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  line-height: 1.4;
  color: #01314b;
  text-align: center;
  margin: 6px; }

h3 {
  font-family: "Montserrat";
  text-align: center;
  font-size: 24px;
  line-height: 1.5;
  color: #01314b; }

.case-study-title-h2 {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 34px;
  line-height: 1.4;
  color: #01314b;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=0, Direction=0, Color=#01314B)";
  /*IE 8*/
  text-shadow: 0 1px 0 #01314B;
  /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=0, Direction=135, Color=#01314B);
  /*IE 5.5-7*/ }

.case-study-info-h2 {
  text-align: left;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 22px;
  color: #01314b;
  font-weight: 500;
  padding-top: 15px; }

h6 {
  font-family: 'Source Sans Pro';
  text-align: center;
  font-size: 20px;
  line-height: 1.6;
  color: #01314b;
  font-weight: normal; }

p {
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #8A8A8A; }

a {
  font-family: 'Source Sans Pro';
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 16px; }

.cover {
  background-image: url(img/logo/bg-photo-1-min.jpg);
  background-size: 100% 100%;
  position: relative; }

.logo {
  width: 50px;
  height: 35px; }

.menu {
  font-family: 'Source Sans Pro'; }

nav {
  display: flex;
  flex-direction: row;
  padding: 32px 100px;
  background-color: transparent;
  margin: 0 auto; }

.fixed-nav nav {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: black;
  margin: 0; }

.second-header nav {
  display: flex;
  flex-direction: row;
  padding: 32px 100px;
  background-color: black;
  transition: background-color 0.3s linear;
  margin: 0 auto; }

.spacer {
  flex-grow: 1; }

.child {
  z-index: 1000; }

.home {
  display: flex;
  flex-direction: column;
  align-items: center; }

.container {
  padding: 100px 0 40px 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

.main-logo {
  height: 200px;
  z-index: 1000; }

.slogan {
  padding-top: 35px;
  font-size: 30px;
  text-align: center;
  color: white; }

.main-partners {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 56px 0 70px 0;
  z-index: 0;
  width: 80%; }

.partner, .client, .testimonials {
  display: flex;
  flex-direction: column;
  align-items: center; }

.main-partners img {
  padding-top: 20px;
  opacity: 0.7; }

.notable-partner {
  font-family: "Montserrat";
  color: white;
  opacity: 0.7; }

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px auto 100px;
  width: 80%;
  padding-left: 2%;
  padding-right: 2%; }

.about p {
  font-family: "Source Sans Pro";
  text-align: center;
  font-size: 18px;
  color: #01314b;
  font-weight: normal; }

.services {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px 0 100px; }

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px 0 auto; }

.footer {
  padding: 100px 0;
  background-color: rgba(0, 0, 0, 0.88);
  width: 100%; }

.footer-p {
  color: white;
  padding-left: 20px;
  padding-right: 20px; }

.wrapper-services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  background-color: #F5F5F5;
  width: 100%;
  padding-bottom: 60px;
  justify-items: center; }

.other-services {
  width: 100%;
  background-color: #F5F5F5;
  text-align: center;
  padding: 60px 0; }

.wrapper-services div ul {
  text-align: left;
  padding: 0 50px 0 70px; }

.wrapper-services div ul li {
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  line-height: 1.5em;
  color: #01314b;
  list-style-type: square; }

.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  background-color: #F5F5F5;
  padding: 150px 0; }

.elementor-background-overlay {
  background-color: rgba(0, 0, 0, 0.76);
  opacity: 0.92;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute; }

#services-box {
  padding: 0 100px;
  background-color: #F5F5F5; }

.icons {
  display: flex;
  justify-content: center; }

#mail {
  color: #248ab8;
  text-transform: lowercase;
  font-size: 20px; }

.services-item p {
  padding: 0 40px; }

.services-item {
  text-align: center;
  padding: 30px 0;
  justify-self: center;
  background-color: white;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=21, Direction=45, Color=#000000)";
  /*IE 8*/
  -moz-box-shadow: -2px 2px 21px rgba(0, 0, 0, 0.3);
  /*FF 3.5+*/
  -webkit-box-shadow: -2px 2px 21px rgba(0, 0, 0, 0.3);
  /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
  box-shadow: -2px 2px 21px rgba(0, 0, 0, 0.3);
  /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=21, Direction=135, Color=#000000);
  /*IE 5.5-7*/ }

.wrapper-services h3 {
  text-transform: uppercase;
  font-size: 22px; }

.wrapper-case-studies-cards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 150px auto 0; }

.contact-container {
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0 50px; }

.case-studies-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px 0; }

.case-study-card-1 {
  background-image: url(img/case_studies/cs1.webp);
  height: 460px;
  width: 449px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=23, Direction=93, Color=#000000)";
  /*IE 8*/
  -moz-box-shadow: 1px 20px 23px -11px rgba(0, 0, 0, 0.4);
  /*FF 3.5+*/
  -webkit-box-shadow: 1px 20px 23px -11px rgba(0, 0, 0, 0.4);
  /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
  box-shadow: 1px 20px 23px -11px rgba(0, 0, 0, 0.4);
  /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=23, Direction=135, Color=#000000);
  /*IE 5.5-7*/ }

.case-study-card-2 {
  background-image: url(img/case_studies/cs2.webp);
  height: 500px;
  width: 475px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=50, Direction=0, Color=#000000)";
  /*IE 8*/
  -moz-box-shadow: 0 18px 50px rgba(0, 0, 0, 0.4);
  /*FF 3.5+*/
  -webkit-box-shadow: 0 18px 50px rgba(0, 0, 0, 0.4);
  /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
  box-shadow: 0 18px 50px rgba(0, 0, 0, 0.4);
  /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=50, Direction=135, Color=#000000);
  /*IE 5.5-7*/ }

.case-study-card-3 {
  background-image: url(img/case_studies/cs3.webp);
  height: 460px;
  width: 449px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=23, Direction=93, Color=#000000)";
  /*IE 8*/
  -moz-box-shadow: 1px 20px 23px -11px rgba(0, 0, 0, 0.4);
  /*FF 3.5+*/
  -webkit-box-shadow: 1px 20px 23px -11px rgba(0, 0, 0, 0.4);
  /*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
  box-shadow: 1px 20px 23px -11px rgba(0, 0, 0, 0.4);
  /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=23, Direction=135, Color=#000000);
  /*IE 5.5-7*/ }

.contact-card-1, .contact-card-3 {
  height: 100px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.contact-card-2 img {
  width: 100px;
  height: 100px; }

.case-study-card-description {
  color: white;
  padding: 50px 60px; }

.case-study-card {
  display: flex;
  flex-direction: column;
  text-align: center; }

.case-study-container {
  display: flex;
  max-width: 1200px;
  margin: 0 100px; }

.column.left {
  width: 80%; }

.column.right {
  width: 20%;
  padding-left: 100px;
  display: flex;
  flex-direction: column; }

.left p {
  text-align: left;
  color: black;
  color: #01314b; }

.case-study-info {
  padding-bottom: 10px; }

.case-study-title {
  padding-bottom: 60px; }

.case-study-info ul li {
  font-family: "Source Sans Pro";
  font-style: normal;
  color: #01314b;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1.5em; }

.case-study-icon {
  padding-right: 5px;
  min-width: 50px; }

.sidebar-info {
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro";
  color: #01314b; }

.sidebar-row {
  display: flex;
  flex-direction: row;
  padding-bottom: 50px;
  align-items: flex-start; }

.sidebar-info-title {
  font-size: 18px; }

.case-study-hr {
  background-color: #51a6d5;
  height: 2px;
  border: 0; }

.case-study-gb {
  padding: 100px 0;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center; }

.case-study-mlg, .case-study-crib {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center; }

.contact-card {
  display: flex;
  align-items: center;
  padding: 0 10px; }

.ads-container {
  background-color: rgba(0, 0, 0, 0.88);
  padding-bottom: 50px; }

.about-us-main-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding-top: 80px;
  margin: 0 auto 0;
  width: 100%; }

.about-us-main-items p {
  color: white;
  font-size: 18px; }

.about-us-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px; }

.clients-partners-container {
  padding-top: 80px; }

.clients-partners-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 40px 0 64px 0; }

.clients-partners-row img:hover, .partner-activision svg:hover, .partner-blizzard svg:hover, .partner-mlg svg:hover {
  transform: scale(1.05); }

.contact-a {
  color: #01314b;
  text-transform: none;
  font-size: 18px; }

.we-offer-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 150px 0; }

.we-offer-column {
  padding-right: 20px;
  padding-left: 20px;
  max-width: 400px; }

.wrapper-main-services-p {
  color: #01314b; }

.contact-info-h6 {
  margin: 0; }

.contact-info {
  padding: 60px 0 40px; }

.contact-info {
  text-align: center; }

.btn-contact, .btn-read-more {
  text-transform: capitalize;
  background-color: #51a6d5;
  color: white;
  text-decoration: none;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 18px; }

.btn-read-more {
  margin-top: 20px; }

.btn-contact:hover, .btn-read-more:hover {
  background-color: #01314b; }

.menu .icon {
  display: none; }

.mobile-nav {
  display: none; }

.logo-mobile {
  position: relative; }

@media screen and (max-width: 1300px) {
  .wrapper-services {
    grid-template-columns: repeat(2, 1fr); } }

@media screen and (max-width: 1325px) {
  .we-offer-container {
    flex-direction: column;
    align-items: center; } }

@media screen and (max-width: 1380px) {
  .wrapper-case-studies-cards {
    flex-direction: column; } }

@media screen and (max-width: 1024px) {
  .cover {
    height: auto; } }

@media screen and (max-width: 950px) {
  .cover {
    height: auto; }
  .header {
    display: none; }
  .mobile-nav {
    padding: 0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.88); }
  .mobile-nav #links {
    display: none; }
  .mobile-nav a {
    color: white;
    padding: 15px 16px;
    text-decoration: none;
    font-family: 'Source Sans Pro';
    font-size: 15px;
    text-transform: uppercase;
    display: block; }
  #links {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 11;
    text-align: right;
    background-color: black; }
  .logo-mobile {
    z-index: 1000; }
  .mobile-nav a.icon {
    font-size: 17px;
    color: white;
    background: transparent;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1; }
  .home {
    position: relative; }
  .client {
    width: 100px; }
  .partner {
    padding-left: 50px;
    width: 100px; }
  .notable-partner {
    font-size: 10px; }
  .main-partners img {
    padding-top: 2px;
    height: 30px; }
  .main-logo {
    height: 10%;
    width: 70%; }
  .wrapper-services {
    grid-template-columns: repeat(1, 1fr); }
  .slogan {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 22px; }
  h2 {
    font-size: 24px; }
  .about {
    margin: 150px auto 50px; }
  .about p {
    font-size: 18px; }
  .about-us-item {
    width: 100%;
    padding-top: 20px; }
  .about-us-item p {
    font-size: 16px;
    padding-right: 20px;
    padding-left: 20px; }
  .about-us-main-items {
    padding-top: 30px; }
  .services {
    margin: 150px auto 0; }
  h3 {
    font-size: 20px; }
  .we-offer-column {
    padding-bottom: 30px; }
  .wrapper-services h3 {
    font-size: 20px; }
  .case-studies-cards {
    margin: 150px auto 0; }
  h1 {
    font-size: 24px; }
  .partners {
    padding: 150px 0; }
  .contact {
    margin: 150px auto 0; }
  .contact h6 {
    font-size: 18px; }
  .btn-contact {
    font-size: 16px; }
  .contact-card {
    padding: 0 10px;
    width: 100%;
    justify-content: center; }
  .contact-a {
    font-size: 16px; }
  .footer-p {
    font-size: 14px; }
  .about-us-item p {
    width: 50%; }
  .wrapper-case-studies-cards {
    flex-direction: column; }
  .we-offer-container {
    flex-direction: column;
    align-items: center; }
  .service-item-spacer {
    display: none; }
  .clients-partners-row {
    flex-direction: column;
    text-align: center;
    margin: 0; }
  .contact-info {
    padding-left: 20px;
    padding-right: 20px; }
  .services-item {
    width: 90%; }
  .logo {
    z-index: 1111; }
  .case-study-title-h2 {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: normal;
    font-size: 30px;
    line-height: 1.4;
    color: #01314b; }
  .case-study-info-h2 {
    text-align: left;
    font-family: "Montserrat";
    text-transform: uppercase;
    font-size: 20px;
    color: #01314b;
    font-weight: 500;
    padding-top: 15px; }
  .partner-activision, .partner-blizzard {
    padding-bottom: 30px; }
  .partner-mlg {
    padding-bottom: 20px; }
  .partner-tigretech {
    padding-bottom: 20px; } }

@media screen and (max-width: 600px) {
  .we-offer-container h3, .wrapper-services h3 {
    margin: 0; }
  .main-logo {
    height: 10%;
    width: 50%; }
  .wrapper-services {
    grid-template-columns: repeat(1, 1fr); }
  .logo {
    height: 20px;
    width: 30px; }
  .slogan {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px; }
  .clients-partners-row {
    text-align: center; }
  .wrapper-services {
    grid-gap: 20px; }
  .service-item-spacer {
    display: none; }
  .about, .services, .case-studies-cards {
    margin: 100px auto; }
  .contact {
    margin: 100px auto 50px; }
  .about h6 {
    margin: 0; }
  .about-us-item p {
    width: 50%; }
  .we-offer-container {
    padding: 50px 0 100px; }
  h2 {
    font-size: 22px;
    text-transform: uppercase; }
  .we-offer-container {
    margin-top: 20px; }
  .wrapper-case-studies-cards {
    margin: 50px auto 0; }
  .contact-info {
    padding: 60px 40px 40px; }
  .clients-partners-row {
    flex-direction: column; }
  .partners {
    padding: 60px 0; }
  .services-item {
    width: 90%; }
  .cover {
    height: auto; } }

@media screen and (max-width: 460px) {
  .wrapper-services {
    grid-template-columns: repeat(1, 1fr); }
  #services-box {
    padding: 0; }
  .contact-info {
    padding: 60px 20px 40px; }
  h2 {
    font-size: 20px;
    text-transform: uppercase; }
  h3, .wrapper-services h3 {
    font-size: 18px; }
  .contact h6 {
    font-size: 16px; }
  .about p, .about-us-item p, .wrapper-main-services-p, p, .wrapper-services div ul li, .btn-contact, .contact-a {
    font-size: 14px; }
  .section-icon {
    width: 20px; }
  .about-us-main-items img, .we-offer-container img, .wrapper-services img {
    width: 40px; }
  .we-offer-container h3, .wrapper-services h3 {
    margin: 0; }
  .services-item {
    margin-left: 10px;
    margin-right: 10px; }
  .case-study-card {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    height: 422px;
    width: 90%; }
  .wrapper-services {
    grid-gap: 20px; }
  .service-item-spacer {
    display: none; }
  .logo {
    width: 22px;
    height: 16px; }
  .mobile-nav a.icon {
    font-size: 12px; }
  .about, .services, .case-studies-cards {
    margin: 100px auto; }
  .contact {
    margin: 100px auto 50px; }
  .about h6 {
    margin: 0; }
  .about-us-item p {
    width: 50%; }
  .we-offer-container {
    padding: 50px 0 100px; }
  .we-offer-container p {
    width: 60%; }
  .we-offer-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .wrapper-case-studies-cards {
    margin: 50px auto 0; }
  .partners {
    padding: 60px 0; }
  .clients-partners-row {
    display: flex;
    flex-direction: column; }
  .cover {
    height: auto; }
  .main-partners {
    width: 100%; }
  .partner-activision svg, .partner-blizzard svg {
    height: 30px; }
  .partner-mlg svg {
    height: 20px; }
  .partner-tigretech img {
    height: 50px; }
  .partner-crib img {
    height: 60px; } }

@media screen and (min-width: 1681px) {
  .about-us-item {
    width: 500px; }
  .wrapper-services {
    width: 70%;
    margin-left: 15%; }
  .we-offer-column {
    max-width: 540px; }
  .about {
    width: 61%; }
  .container {
    padding: 340px 0 40px 0; }
    .container .main-logo {
      height: 360px; }
  .main-partners {
    padding: 126px 0 123px 0; }
    .main-partners img {
      height: 60px; } }

@media screen and (max-width: 375px) {
  .container {
    padding: 50px 0 40px 0; }
  .main-partners {
    width: 80%;
    padding: 0px 0 35px 0; }
    .main-partners img {
      height: 25px; }
    .main-partners .client {
      width: 100px; }
    .main-partners .partner {
      width: 100px; }
  .partner-mlg svg {
    height: 20px; }
  .partner-tigretech img {
    height: 50px; }
  .partner-crib img {
    height: 60px; } }

.partner-activision:not(:hover) svg path:not(.do-not-touch) {
  fill: #242424; }

.partner-blizzard:not(:hover) svg path:not(.do-not-touch) {
  fill: #242424; }

.partner-mlg:not(:hover) svg path:not(.do-not-touch) {
  fill: #242424; }

.partner-tigre img {
  height: 100px; }
